import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "./CustomParallaxHome.css";

import SeperateBg from "./Images/kaash-seperate-bg.png";

// Images
import BannerVideo from "./Images/video-new.mp4";
import OurStoryImg from "./Images/parallax/bg-story.png";
import OurBrandsImg from "./Images/parallax/bg-brands.png";
import BlogsImg from "./Images/parallax/bg-blogs.png";
import ContactImg from "./Images/parallax/bg-contact.png";

// Story
import { FaArrowRightLong } from "react-icons/fa6";

// Brands
import OurBrands from "./Components/OurBrands";

// Blogs
import { BlogsData } from "../Blogs/Components/BlogData";
import { IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";

// Highlights and Contact
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Scrollbar } from "swiper/modules";
import ContactForm from "../../components/ContactForm";

export default function CustomParallaxHome() {
  // Check component
  const [currentBack, setCurrentBack] = useState(0);

  // Check scrolling
  const [scrollUp, setScrollUp] = useState(null);

  // Navigate
  const navigate = useNavigate();

  // Views
  const [refBanner, inViewBanner] = useInView();

  const [refBig, inViewBig] = useInView();

  const [refStory, inViewStory] = useInView();
  const [refBrands, inViewBrands] = useInView();
  const [refBlogs, inViewBlogs] = useInView();
  const [refHighLights, inViewHighLights] = useInView();

  useEffect(() => {
    const data = [
      // inViewBanner,
      inViewBig,
    ];
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        setCurrentBack(i);
      }
    }
  }, [inViewBig]);
  // useEffect(() => {
  //   const data = [
  //     inViewBanner,
  //     inViewStory,
  //     inViewBrands,
  //     inViewBlogs,
  //     inViewHighLights,
  //   ];
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i]) {
  //       setCurrentBack(i);
  //     }
  //   }
  // }, [inViewBanner, inViewStory, inViewBrands, inViewBlogs, inViewHighLights]);

  // Handle scroll
  const data = [
    // inViewBanner,
    inViewBig,
  ];
  // const data = [
  //   inViewBanner,
  //   inViewStory,
  //   inViewBrands,
  //   inViewBlogs,
  //   inViewHighLights,
  // ];
  const handleWheelChange = (e) => {
    // console.log('wheel event', e.deltaY)
    if (e.deltaY > 0) {
      setScrollUp(false);
    } else {
      setScrollUp(true);
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        setCurrentBack(i);
      }
    }
  };

  // Story
  const handleKnowClick = () => {
    navigate("/about-us");
    window.scrollTo(0, 0);
  };

  // Blogs
  const [popUp, setPopUp] = useState(false);
  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const handleViewClick = () => {
    navigate("/blogs");
    window.scrollTo(0, 0);
  };
  const handleReadMoreClick = (blogIndex) => {
    setSelectedBlogIndex(blogIndex); // Set the index of the clicked blog
    setPopUp(true); // Open the popup
  };

  return (
    <div className="custom-parallax position-relative">
      {/* Banner  */}
      <div className="banner v">
        <div className="position-relative">
          <video
            id="background-video"
            className="w-100 inline video-wrapper video-player hero-video-design "
            playsInline
            controls={false}
            muted
            autoPlay
            loop
          >
            <source src={require("./Images/video-new.mp4")} type="video/mp4" />
          </video>
          <div className="position-absolute top-0 left-0 w-100 h-100">
            <div className="d-flex h-100">
              <div className="col-12 m-auto">
                <div className="">
                  <h1 className={` text-center banner-title`} ref={refBanner}>
                    Create anything you can imagine <br />
                    <span className="orange-color">with 3D Printing</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Backgrounds  */}
      <div className="backgrounds backgrounds-single">
        {currentBackgrounds.map((bg, i) => (
          <div
            key={i}
            className={`position-relative h-100 ${
              currentBack === i ? "visible" : "visually-hidden"
            }`}
          >
            <div className={`h-100 w-100  position-absolute top-0 start-0 `}>
              {bg}
            </div>
          </div>
        ))}
      </div>

      {/* Content */}
      <div className="container start-with z-2" onWheel={handleWheelChange}>
        <div className=" text-white" ref={refBig}>
          <div className="our-story v-height" id="ourstory">
            <div className="d-flex h-100">
              <div className="col-12 m-auto">
                <div className={`zoom-content`}>
                  <div className="d-flex flex-wrap align-items-center">
                    <div
                      className={`col-lg-12  col-xl-6 col-12 mt-md-4 rounded`}
                      ref={refStory}
                    >
                      <img
                        src={require("./Images/groupimg.png")}
                        alt=""
                        className="img-fluid our-story-image-group d-none d-md-block rounded"
                      />
                    </div>
                    <div className={`col-xl-6 col-12 px-xl-5 `}>
                      <p className="text-heading-orange mt-2 mb-1 mt-lg-4 mt-md-4">
                        Kaash Studio
                      </p>
                      <h1 id="section-id">Our Story Unfolded</h1>

                      <p
                        className="para-ourstory"
                        style={{ textAlign: "justify" }}
                      >
                        At Kaash Studio, our journey is a testament to the power
                        of passion and innovation. Founded with a vision to
                        redefine the possibilities of 3D printing and design,
                        our story began in ’23 as a humble endeavour.
                      </p>
                      <p className="para-ourstory" style={{ textAlign: "justify" }}>
                        {" "}
                        At our core, we are more than a company; we are
                        creators, innovators, and visionaries. The narrative
                        that defines us – from humble beginnings to becoming a
                        driving force in 3D printing and designing. Every
                        project, every collaboration, contributes to a legacy
                        built on excellence and creativity.
                      </p>
                      <button
                        className="text-dark p-2 px-4 rounded border-muted text-secondary knowmore-btn bg-white"
                        onClick={handleKnowClick}
                      >
                        Know more <FaArrowRightLong fill="grey" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Brands  */}
          <div className="our-brands v-height" id="ourbrands">
            <div className="d-flex ">
              <div className="col-12 m-auto">
                <div
                  className=" scroll-animation "
                  // ref={refBrands}
                >
                  <OurBrands
                    className={` mt-lg-1 mt-2 zoom-content `}
                    refBrands={refBrands}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Blogs  */}
          <div className="blogs v-height" id="blogs">
            <div className="d-flex pt-3">
              <div className="col-12 m-auto">
                <div>
                  <div
                    className="d-flex flex-wrap pt-5 pt-xl-0 justify-content-between mb-lg-0 mb-2 mb-xl-1"
                    ref={refBlogs}
                  >
                    <h1 className={`px-lg-3 px-md-3 blogs-title`}>
                      Blogs & News
                    </h1>
                    <div className={`col-xl-3 col-lg-4 pe-xl-4 pe-md-3 `}>
                      <button
                        className="text-dark w-100 p-2 bg-white shadow border-muted border rounded fw-semibold  "
                        onClick={handleViewClick}
                      >
                        {" "}
                        View All <FaArrowRightLong />
                      </button>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap  justify-content-between">
                    {BlogsData.slice(0, 3).map((data, index) => (
                      <div
                        className={`col-12 mb-lg-0 mb-4 mb-lg-5 mt-md-0  mb-md-4 pe-md-3 px-md-3 col-xl-4 mt-xl-0 col-lg-6 px-xl-4  col-md-6 pe-lg-4 px-lg-4 `}
                      >
                        <div
                          onClick={() => handleReadMoreClick(index)}
                          className="text-dark cursor-pointer card card-view-height "
                        >
                          <div className=" ">
                            {/* <img
                              src={data.img}
                              alt=""
                              height={200}
                              width="100%"
                              className="rounded-top "
                            /> */}
                          </div>
                          <div
                            style={{
                              backgroundColor: "#f5f7fa",
                              height: "100%",
                            }}
                            className="card-body rounded-bottom  "
                          >
                            <h4 className="h-50 ">{data.title}</h4>
                            <div className="d-flex flex-wrap justify-content-between">
                              <span className="text-secondary mt-3 mt-xl-4 mt-xxl-4  mt-md-3">
                                {data.date}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{ backgroundColor: "#f5f7fa" }}
                            className="card-footer border-0 mt-0 pt-0"
                          >
                            <button
                              onClick={() => handleReadMoreClick(index)}
                              className={` rounded  w-100 p-2 border-0 bg-white shadow`}
                            >
                              Read More
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Blogs Popup  */}
                  {BlogsData.map((data, index) => (
                    <Modal
                      show={popUp && selectedBlogIndex === index}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      key={index}
                      className=""
                    >
                      <Modal.Header className=" border-bottom-0">
                        <Modal.Title
                          className=""
                          id="contained-modal-title-vcenter"
                        >
                          <div className="d-flex flex-wrap justify-content-between">
                            <div className="orange-color text-center fw-normal px-5 p-1 fs-6 rounded-5 orange-bg ">
                              BLOGS
                            </div>
                          </div>
                        </Modal.Title>
                        <IoClose
                          size={25}
                          className="cursor-pointer"
                          onClick={() => setPopUp(false)}
                        />
                      </Modal.Header>
                      <Modal.Body className=" p-3">
                        <img
                          src={data.img}
                          alt={`Blogs-${index + 1}`}
                          className="img-fluid rounded"
                        />
                        <div className="pt-3">
                          <h4>{data.title}</h4>
                          <div>
                            <p style={{ textAlign: "justify" }} className="">
                              {data.paragraphA}
                            </p>
                            <h6>
                              {data.linkHeading}{" "}
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={data.link}
                              >
                                {data.linkName}
                              </a>
                            </h6>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Highlights  */}
          <div className="highlight pb-5">
            <div className="d-flex h-100">
              <div className="col-12 m-auto">
                <>
                  {/* Highlights  */}
                  <>
                    <h6
                      className={`text-center client-higlights-heading`}
                      ref={refHighLights}
                    >
                      CLIENT HIGHLIGHTS
                    </h6>
                    <hr />
                    <Swiper
                      modules={[Navigation, Autoplay, Pagination, Scrollbar]}
                      autoplay={{ delay: 0.00001 }}
                      className={`py-md-4`}
                      style={{ backgroundColor: "#E5E5E6" }}
                      breakpoints={{
                        0: { slidesPerView: 2 },
                        480: { slidesPerView: 2 },
                        576: { slidesPerView: 4 },
                        // 1200: { slidesPerView: 3 },
                      }}
                      loop
                      speed={2500}
                    >
                      {HighlightsItems.map((data, i) => (
                        <SwiperSlide className="text-center" key={i}>
                          <div className="mx-md-4 mx-2">
                            <img
                              src={data}
                              alt="Company Logo"
                              className="img-fluid w-100 px-3"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <hr />
                  </>
                </>
              </div>
            </div>
          </div>

          {/* Contact  */}
          <div className="contact v-height" id="contact">
            <div className="d-flex h-100">
              <div className="col-12 m-auto">
                {/* Contact  */}
                <div className=" text-black ">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="py-5"></div> */}
        </div>
      </div>
    </div>
  );
}

const HighlightsItems = [
  require("./Images/logojohndeere.png"),
  require("./Images/datart2.png"),
  require("./Images/Logos Grayscale.png"),
  require("./Images/logossr.png"),
  require("./Images/mizani.png"),
  require("./Images/prayosha.png"),
  require("./Images/prayosha2.png"),
];

const currentBackgrounds = [
  // <video
  //   // className="w-100 h-100"
  //   id="background-video"
  //   className="w-100 inline video-wrapper video-player hero-video-design"
  //   playsInline
  //   controls={false}
  //   muted
  //   autoPlay
  //   loop
  // >
  //   <source src={BannerVideo} type="video/mp4" />
  // </video>,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
  <img src={OurStoryImg} alt="Story" className="w-100 h-100" />,
  <img src={OurBrandsImg} alt="Brands" className="w-100 h-100" />,
  <img src={BlogsImg} alt="Blogs" className="w-100 h-100" />,
  <img src={ContactImg} alt="HighLights" className="w-100 h-100" />,
];
