import React, {  useRef, useState } from "react";

import "./HomePage.css";


import Modal from "react-bootstrap/Modal";

import { useNavigate, Link } from "react-router-dom";
import { BlogsData } from "../Blogs/Components/BlogData";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

import "aos/dist/aos.css";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Mousewheel,
  Keyboard,
} from "swiper/modules";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";


import ContactForm from "../../components/ContactForm";
import { useInView } from "react-intersection-observer";
import OurBrands from "./Components/OurBrands";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function HomePage() {
  const [refAnimate, inViewAnimate] = useInView({
    triggerOnce: true,
  });

  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate("/blogs");
    window.scrollTo(0, 0);
  };

  const handleKnowClick = () => {
    navigate("/about-us");
    window.scrollTo(0, 0);
  };

  const swiperRef = useRef();
  const [popUp, setPopUp] = useState(false);

  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null); // State to store the index of the selected blog

  const handleReadMoreClick = (index) => {
    setSelectedBlogIndex(index); // Set the index of the clicked blog
    setPopUp(true); // Open the popup
  };

  // For animations
  const [ref, inView] = useInView();

  const [refHighLights, inViewHighLights] = useInView({
    triggerOnce: true,
  });

  // -----------------------------//
  //----------------------------------------Animation------------------------------------------//

  const [offsetY, setOffsetY] = useState(0);

  const [isVideoVisible, setIsVideoVisible] = useState(true);

 

  

  const modalClass = "custom-modal";

  return (
    <>
      
      <Swiper
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          FreeMode,
          Mousewheel,
          Keyboard,
        ]}
        spaceBetween={0}
        slidesPerView={1}
        ref={swiperRef}
        style={{ height: "100vh" }}
        direction="vertical"
        // delay={0.001}
        speed={2000}
        mousewheel={{
          enable: true,
        }}
        keyboard={{
          enabled: true,
          pageUpDown: true,
        }}
      >
        <SwiperSlide id="home" className="h-100">
          <Header />
          <div ref={refAnimate} className="">
            <div className="home-banner-kaash position-relative ">
              <div>
                <video
                  id="background-video"
                  className="w-100 inline video-wrapper video-player hero-video-design"
                  playsInline
                  controls={false}
                  muted
                  autoPlay
                  loop
                >
                  <source
                    src={require("./Images/video-new.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
              <h1
                id="text-overlay"
                className="col-11 col-md-10 col-lg-8 mx-auto home-heading-title fade-in"
              >
                Create anything you can imagine <br />
                <span className="orange-color">with 3D Printing</span>
              </h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide id="ourstory" className="h-100">
          <section
            ref={refAnimate}
            className="ourstory-background h-100 nav-link"
          >
            <div className="scroll-animation pt-xxl-5  pb-xl-5 pt-3 pb-5  ">
              <div
                className={`container pt-xl-2  pb-xl-5  mt-xxl-5  mt-lg-1 mt-2 zoom-content `}
                // style={{ transform: `scale(${zoomScale})` }}
              >
                <div className="d-flex flex-wrap pt-xl-5  mt-xl-5 align-items-center">
                  <div
                    className={`col-lg-12 col-xl-6 col-12 mt-md-4 rounded ${
                      inView && "fade-in"
                    }`}
                  >
                    <img
                      src={require("./Images/groupimg.png")}
                      alt=""
                      className="img-fluid our-story-image-group d-none d-md-block rounded"
                    />
                  </div>
                  <div
                    className={`col-xl-6 col-12 px-xl-5 ${
                      inView && "ease-in ease-in-right"
                    }`}
                  >
                    <p
                      className="text-heading-orange mt-2 mb-1 mt-lg-4 mt-md-4"
                      ref={ref}
                    >
                      Kaash Studio
                    </p>
                    <h1 id="section-id">Our Story Unfolded</h1>
                    <img
                      src={require("./Images/groupimg.png")}
                      alt=""
                      className="img-fluid d-md-none rounded my-2"
                    />
                    <p style={{ textAlign: "justify" }}>
                      At Kaash Studio, our journey is a testament to the power
                      of passion and innovation. Founded with a vision to
                      redefine the possibilities of 3D printing and design, our
                      story began in ’23 as a humble endeavor.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      {" "}
                      At our core, we are more than a company; we are creators,
                      innovators, and visionaries. The narrative that defines us
                      – from humble beginnings to becoming a driving force in 3D
                      printing and designing. Every project, every
                      collaboration, contributes to a legacy built on excellence
                      and creativity.
                    </p>
                    <button
                      className="text-dark p-2 px-4 rounded border-muted text-secondary knowmore-btn bg-white"
                      onClick={handleKnowClick}
                    >
                      Know more <FaArrowRightLong fill="grey" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide id="ourbrands" className="h-100">
          <section ref={refAnimate} className="unique-offerings h-100">
            <div className=" pt-xxl-5 pt-xl-5  scroll-animation ">
              <OurBrands
                className={` mt-lg-1 mt-2  zoom-content  ${
                  inView ? "fade-in" : ""
                } `}
              />
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide id="blogs" className="">
          <section ref={refAnimate}>
            <div className="container blogs-section pt-lg-4 pt-xl-0">
              <div className="d-flex flex-wrap pt-5 pt-xl-0 pt-xxl-5 pt-xl-4 justify-content-between mb-lg-0 mb-2 mb-xl-1">
                <h1 className="px-lg-3  px-md-3">Blogs & News</h1>
                <div className="col-xl-3 col-lg-4 pe-xl-4 pe-md-3">
                  <button
                    className="text-dark w-100 p-2 bg-white shadow border-muted border rounded fw-semibold  "
                    onClick={handleViewClick}
                  >
                    {" "}
                    View All <FaArrowRightLong />
                  </button>
                </div>
              </div>

              <div className="d-flex flex-wrap  justify-content-between">
                {BlogsData.slice(0, 3).map((data, index) => (
                  <div className="col-12 mb-lg-0 mb-4 mb-lg-5 mt-md-0  mb-md-4 pe-md-3 px-md-3 col-xl-4 mt-xl-0 col-lg-6 px-xl-4 mb-xl-0 pb-xl-0  col-md-6 pe-lg-4 px-lg-4">
                    <div
                      onClick={() => handleReadMoreClick(index)}
                      className="text-dark cursor-pointer card card-view-height "
                    >
                      <div className=" ">
                        <img
                          src={data.img}
                          alt=""
                          height={200}
                          width="100%"
                          className="rounded-top"
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f5f7fa",
                          height: "100%",
                        }}
                        className="card-body rounded-bottom  "
                      >
                        <h5 className="h-50 ">{data.title}</h5>
                        <div className="d-flex flex-wrap justify-content-between">
                          <span className="text-secondary mt-3 mt-xl-4 mt-xxl-4  mt-md-3">
                            {data.date}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ backgroundColor: "#f5f7fa" }}
                        className="card-footer border-0 mt-0 pt-0"
                      >
                        <button
                          onClick={() => handleReadMoreClick(index)}
                          className={` rounded  w-100 p-2 border-0 bg-white shadow-on-hover`}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section ref={refAnimate}>
            <div className="container mt-xl-4 mt-xxl-5 pt-xxl-5 mt-lg-5 mt-3 mb-3 pb-lg-1 pt-lg-3 pt-xl-0 mt-xl-0">
              <h6
                className={`text-center client-higlights-heading ${
                  inViewHighLights && "fade-in"
                }`}
                ref={refHighLights}
              >
                CLIENT HIGHLIGHTS
              </h6>
              <hr />
              <Swiper
                modules={[
                  Navigation,
                  Autoplay,
                  Pagination,
                  Scrollbar,
                  A11y,
                  FreeMode,
                ]}
                autoplay={{ delay: 0.00001 }}
                // spaceBetween={50}
                // slidesPerView={3}
                // pagination={{ clickable: true }}
                // onActiveIndexChange={handleSlideChange}
                // centeredSlides
                className="my-md-4"
                // navigation
                breakpoints={{
                  0: { slidesPerView: 2 },
                  480: { slidesPerView: 2 },
                  576: { slidesPerView: 4 },
                  // 1200: { slidesPerView: 3 },
                }}
                loop
                speed={2500}
                style={{ backgroundColor: "#E5E5E6" }}
              >
                {HighlightsItems.map((data, i) => (
                  <SwiperSlide className="text-center" key={i}>
                    <div className="mx-md-4 mx-2">
                      <img
                        src={data}
                        alt="Company Logo"
                        className="img-fluid w-100 px-3"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <hr />
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide id="contact" className="">
          <section>
            <div className="p-xl-4 px-0 pt-0 pt-lg-5  contact-form ">
              <ContactForm />
            </div>
            <Footer />
          </section>
        </SwiperSlide>
      </Swiper>

      {BlogsData.map((data, index) => (
        <Modal
          show={popUp && selectedBlogIndex === index}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          key={index}
          className={`${modalClass}`}
        >
          <Modal.Header className=" border-bottom-0">
            <Modal.Title className="" id="contained-modal-title-vcenter">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="orange-color text-center fw-normal px-5 p-1  rounded-5 orange-bg ">
                  BLOGS
                </div>
              </div>
            </Modal.Title>
            <IoClose
              size={25}
              className="cursor-pointer cross-cancel"
              onClick={() => setPopUp(false)}
            />
          </Modal.Header>
          <Modal.Body className=" p-3">
            <img src={data.img} className="img-fluid w-100 rounded" />
            <div className="pt-3">
              <h4>{data.title}</h4>
              <div>
                <p style={{ textAlign: "justify" }} className="">
                  {data.paragraphA}
                </p>
                <h6>
                  {data.linkHeading}{" "}
                  <a className="" target="_blank" href={data.link}>
                    {data.linkName}
                  </a>
                </h6>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ))}
    </>
  );
}

const HighlightsItems = [
  require("./Images/logojohndeere.png"),
  require("./Images/datart2.png"),
  require("./Images/Logos Grayscale.png"),
  require("./Images/logossr.png"),
  require("./Images/mizani.png"),
  require("./Images/prayosha.png"),
  require("./Images/prayosha2.png"),
];
