import { ToastContainer } from "react-toastify";
import "./App.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AboutUs from "./Pages/AboutUs/About";
import Blogs from "./Pages/Blogs/Blog";
import MostPopular from "./Pages/Blogs/Components/MostPopular";
import HomeRoute from "./Pages/HomePage/HomeRoute";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position={"top-right"}
          autoClose={2500}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
        />
        {/* <Header /> */}
        <Routes>
          <Route index element={<HomeRoute />} />
          {/* <Route path="/contact" element={<ContactUs />} /> */}
          <Route
            path="/about-us"
            element={
              <>
                {/* <Header /> */}
                <AboutUs />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/blogs"
            element={
              <>
                {/* <Header /> */}
                <Blogs />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            path="/most-popular/:index"
            element={
              <>
                <Header />
                <MostPopular />
                <Footer />
              </>
            }
          />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
