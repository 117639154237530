import React, { useState } from "react";
import "./FinalHomaPage.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import OurBrands from "./Components/OurBrands";
import { BlogsData } from "../Blogs/Components/BlogData";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Scrollbar } from "swiper/modules";
import ContactForm from "../../components/ContactForm";

function FinalHomePage() {
  // Navigate
  const navigate = useNavigate();

  // Story
  const handleKnowClick = () => {
    navigate("/about-us");
    window.scrollTo(0, 0);
  };

  // Blogs
  const [popUp, setPopUp] = useState(false);
  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const handleViewClick = () => {
    navigate("/blogs");
    window.scrollTo(0, 0);
  };
  const handleReadMoreClick = (blogIndex) => {
    setSelectedBlogIndex(blogIndex); // Set the index of the clicked blog
    setPopUp(true); // Open the popup
  };

  return (
    <>
      {/* Banner  */}
      <div className="home-banner-kaash position-relative banner">
        <div>
          <video
            id="background-video"
            className="w-100 inline video-wrapper video-player hero-video-design"
            playsInline
            controls={false}
            muted
            autoPlay
            loop
          >
            <source src={require("./Images/video-new.mp4")} type="video/mp4" />
          </video>
        </div>
        <div className="position-absolute top-0 left-0 h-100 w-100 d-flex ">
          <h1
            id="text-overlay"
            className="col-11 col-md-10 col-lg-8 m-auto text-center banner-title"
          >
            Create anything you can imagine <br />
            <span className="orange-color">with 3D Printing</span>
          </h1>
        </div>
      </div>

      <div className="single-background">
        {/* Story  */}
        <div
          className={`  zoom-content`}
          // style={{ transform: `scale(${zoomScale})` }}
          id="ourstory"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className={`col-lg-12 col-xl-6 col-12 mt-md-4 rounded `}>
              <img
                src={require("./Images/groupimg.png")}
                alt=""
                className="img-fluid our-story-image-group d-none d-md-block rounded rounded-3"
              />
            </div>
            <div className={`col-xl-6 col-12 px-xl-5 text-white`}>
              <p className="text-heading-orange mt-2 mb-1 mt-lg-4 mt-md-4">
                Kaash Studio
              </p>
              <h1 id="section-id">Our Story Unfolded</h1>
              <img
                src={require("./Images/groupimg.png")}
                alt=""
                className="img-fluid d-md-none rounded my-2"
              />
              <p style={{ textAlign: "justify" }}>
                At Kaash Studio, our journey is a testament to the power of
                passion and innovation. Founded with a vision to redefine the
                possibilities of 3D printing and design, our story began in ’23
                as a humble endeavour.
              </p>
              <p style={{ textAlign: "justify" }}>
                {" "}
                At our core, we are more than a company; we are creators,
                innovators, and visionaries. The narrative that defines us –
                from humble beginnings to becoming a driving force in 3D
                printing and designing. Every project, every collaboration,
                contributes to a legacy built on excellence and creativity.
              </p>
              <button
                className="text-dark p-2 px-4 rounded border-muted text-secondary knowmore-btn bg-white"
                onClick={handleKnowClick}
              >
                Know more <FaArrowRightLong fill="grey" />
              </button>
            </div>
          </div>
        </div>

        {/* Brands  */}
        <div
          id="ourbrands"
          className=" scroll-animation "
          // ref={refBrands}
        >
          <OurBrands
            className={`container our-brands py-5 h-100 d-flex mt-lg-1 mt-2 zoom-content`}
          />
        </div>

        {/* Blogs  */}
        <div id="blogs" className="pb-md-5 pb-4">
          <div
            className={`d-flex flex-wrap pt-5 pt-xl-0 justify-content-between mb-lg-0 mb-2 mb-xl-1`}
          >
            <h1 className={`px-lg-3 px-md-3 text-white  `}>Blogs & News</h1>
            <div className={`col-xl-3 col-lg-4 pe-xl-4 pe-md-3 `}>
              <button
                className="text-dark w-100 p-2 bg-white shadow border-muted border rounded fw-semibold  "
                onClick={handleViewClick}
              >
                {" "}
                View All <FaArrowRightLong />
              </button>
            </div>
          </div>

          <div className="d-flex flex-wrap  justify-content-between">
            {BlogsData.slice(0, 3).map((data, index) => (
               <div
               className={`col-12  pe-md-3 px-md-3 mb-4 col-xl-4 mt-xl-0 col-lg-6 px-xl-3  col-md-6 pe-lg-2 px-lg-2  `}
             >
               <div
                 onClick={() => handleReadMoreClick(index)}
                 className="text-dark cursor-pointer card card-view-height "
               >
                 <div className=" ">
                   <img
                     src={data.img}
                     alt=""
                     height={200}
                     width="100%"
                     className="rounded-top blog-img-card"
                   />
                 </div>
                 <div
                   style={{
                     backgroundColor: "#f5f7fa",
                     height: "100%",
                   }}
                   className="card-body rounded-bottom  "
                 >
                   <h4 className="h-50 blog-card-heading">{data.title}</h4>
                 </div>
                 <span
                   style={{
                     backgroundColor: "#f5f7fa",
                   }}
                   className="text-secondary px-3 blog-text-span"
                 >
                   {data.date}
                 </span>
                 <div
                   style={{ backgroundColor: "#f5f7fa" }}
                   className="card-footer border-0 mt-0 pt-2 "
                 >
                   <button
                     onClick={() => handleReadMoreClick(index)}
                     className={` w-100 p-2 rounded blog-viewall-btn border-1 border-secondary shadow-on-hover`}
                   >
                     Read More{" "}
                     <FaArrowRightLong
                       className="arrow-icon-blog"
                       size={15}
                     />
                   </button>
                 </div>
               </div>
             </div>
            ))}
          </div>
        </div>

        {/* Highlights */}
        <div className="pt-md-5 pt-4">
          <h6 className={`text-center client-higlights-heading `}>
            CLIENT HIGHLIGHTS
          </h6>
          <hr className={`mb-0 `} />
          <Swiper
            modules={[Navigation, Autoplay, Pagination, Scrollbar]}
            autoplay={{ delay: 0.00001 }}
            className={`py-md-4 `}
            style={{ backgroundColor: "white" }}
            breakpoints={{
              0: { slidesPerView: 2 },
              480: { slidesPerView: 2 },
              576: { slidesPerView: 4 },
              // 1200: { slidesPerView: 3 },
            }}
            loop
            speed={2500}
          >
            {HighlightsItems.map((data, i) => (
              <SwiperSlide className="text-center" key={i}>
                <div className="mx-md-4 mx-2">
                  <img
                    src={data}
                    alt="Company Logo"
                    className="img-fluid w-100 px-3"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <hr className={`mt-0 `} />
        </div>

        {/* Contact  */}
        <div className="pt-md-5 pt-4 ">
          <ContactForm />
        </div>
      </div>

      {/* Blogs Popup  */}
      {BlogsData.map((data, index) => (
        <Modal
          show={popUp && selectedBlogIndex === index}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          key={index}
          className=""
        >
          <Modal.Header className=" border-bottom-0">
            <Modal.Title className="" id="contained-modal-title-vcenter">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="orange-color text-center fw-normal px-5 p-1 fs-6 rounded-5 orange-bg ">
                  BLOGS
                </div>
              </div>
            </Modal.Title>
            <IoClose
              size={25}
              className="cursor-pointer"
              onClick={() => setPopUp(false)}
            />
          </Modal.Header>
          <Modal.Body className=" p-3">
            <img
              src={data.img}
              alt={`Blogs-${index + 1}`}
              className="img-fluid rounded"
            />
            <div className="pt-3">
              <h4>{data.title}</h4>
              <div>
                <p style={{ textAlign: "justify" }} className="">
                  {data.paragraphA}
                </p>
                <h6>
                  {data.linkHeading}{" "}
                  <a rel="noopener noreferrer" target="_blank" href={data.link}>
                    {data.linkName}
                  </a>
                </h6>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ))}
    </>
  );
}

const HighlightsItems = [
  require("./Images/logojohndeere.png"),
  require("./Images/datart2.png"),
  require("./Images/Logos Grayscale.png"),
  require("./Images/logossr.png"),
  require("./Images/mizani.png"),
  require("./Images/prayosha.png"),
  require("./Images/prayosha2.png"),
];

export default FinalHomePage;
