// Blogs.js
import React, { useState } from "react";
import "./Blog.css";
import { useNavigate } from "react-router-dom";
import { BlogsData } from "./Components/BlogData";
import Modal from "react-bootstrap/Modal";
import { IoClose } from "react-icons/io5";

import Footer from "../../components/Footer";
import { FaArrowRightLong } from "react-icons/fa6";

import HeaderBlog from "../../components/Headerblog";

function Blogs() {
  const navigate = useNavigate();
  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);

  const [popUp, setPopUp] = useState(false);
  const handleReadMoreClick = (index) => {
    setSelectedBlogIndex(index); // Set the index of the clicked blog
    setPopUp(true); // Open the popup
  };

  const handleClose = () => {
    setPopUp(false);
  };
  return (
    <>
      {/* <HeaderAbout /> */}
      <HeaderBlog />
      {BlogsData.length > 0 ? (
        <div className="blog pt-5 ">
          <div className="container pb-5 ">
            <h2 className="px-lg-3 text-white py-3 mt-5">
              Latest from our blog
            </h2>

            <div className="d-flex flex-wrap ">
              {BlogsData.map(
                (
                  data,
                  index // Limiting to only three items using slice
                ) => (
                  <div
                    className={`col-12  pe-md-3 px-md-3 mb-4 col-xl-4 mt-xl-0 col-lg-6 px-xl-3  col-md-6 pe-lg-2 px-lg-2  `}
                  >
                    <div
                      onClick={() => handleReadMoreClick(index)}
                      className="text-dark cursor-pointer card card-view-height "
                    >
                      <div className=" ">
                        <img
                          src={data.img}
                          alt=""
                          height={200}
                          width="100%"
                          className="rounded-top blog-img-card"
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f5f7fa",
                          height: "100%",
                        }}
                        className="card-body rounded-bottom  "
                      >
                        <h4 className="h-50 blog-card-heading">{data.title}</h4>
                      </div>
                      <span
                        style={{
                          backgroundColor: "#f5f7fa",
                        }}
                        className="text-secondary px-3 blog-text-span"
                      >
                        {data.date}
                      </span>
                      <div
                        style={{ backgroundColor: "#f5f7fa" }}
                        className="card-footer border-0 mt-0 pt-2 "
                      >
                        <button
                          onClick={() => handleReadMoreClick(index)}
                          className={` w-100 p-2 rounded blog-viewall-btn border-1 border-secondary shadow-on-hover`}
                        >
                          Read More{" "}
                          <FaArrowRightLong
                            className="arrow-icon-blog"
                            size={15}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div
            className="container"
            style={{ marginTop: "18rem", marginBottom: "12.3rem" }}
          >
            <div className=" text-center px-2 ">
              <div className="border py-2 px-5 rounded-4">
                <p className="fs-3">
                  No blogs yet, stay tuned ! <br />
                  We have some great content coming your way.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {BlogsData.map((data, index) => (
        <Modal
          onHide={handleClose}
          show={popUp && selectedBlogIndex === index}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          key={index}
        >
          <Modal.Header className=" border-bottom-0">
            <Modal.Title className="" id="contained-modal-title-vcenter">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="orange-color-label-blog text-center fw-normal px-5 p-1  rounded-5 orange-bg ">
                  BLOGS
                </div>
              </div>
            </Modal.Title>
            <IoClose
              size={25}
              className="cursor-pointer close-btn-blog-popup"
              onClick={() => setPopUp(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-3">
            <img src={data.img} className="img-fluid rounded w-100" />
            <div className="pt-3">
              <h4>{data.title}</h4>
              <p style={{ textAlign: "justify" }}>{data.paragraphA}</p>
              <h6>
                {data.linkHeading}{" "}
                <a className="" target="_blank" href={data.link}>
                  {data.linkName}
                </a>
              </h6>
            </div>
          </Modal.Body>
        </Modal>
      ))}
      <Footer />
    </>
  );
}

export default Blogs;
