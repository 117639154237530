import { useEffect, useState } from "react";
import HomePage from "./HomePage";
import NewHomePage from "./NewHomePage";
import CustomParallaxHome from "./CustomParallaxHome";
import ParallaxSlider from "./ParallaxSlider";
import Header from "../../components/Header";
import FinalHomePage from "./FinalHomePage";
import Footer from "../../components/Footer";

export default function HomeRoute() {
  
  const [windowWidth, setWindowWidth] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  return windowWidth > 1024 ? <ParallaxSlider /> : <NewHomePage />;
  
}
