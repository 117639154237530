import React, { useEffect, useRef, useState } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import "./HomePage.css";
import { useLocation } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaArrowDown } from "react-icons/fa6";
import Testimonials from "./Components/Testimonials";
import { useNavigate, Link } from "react-router-dom";
import { BlogsData } from "../Blogs/Components/BlogData";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
// import { Link } from "react-scroll";
// import Aos from "aos";
import "aos/dist/aos.css";
import video from "./Images/video-new.mp4";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Mousewheel,
  Keyboard,
} from "swiper/modules";

import UniqueOfferings from "./Components/UniqueOfferings";

import CardsImage from "./Components/CardsImage";
import ContactForm from "../../components/ContactForm";
import { useInView } from "react-intersection-observer";
import OurBrands from "./Components/OurBrands";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HeaderAbout from "../../components/HeaderAbout";

const NewHomePage = () => {
  // const [refWorks, inViewWorks] = useInView({
  //   triggerOnce: true,
  // });
  const location = useLocation();
  const path = location.pathname;

  const [refAnimate, inViewAnimate] = useInView({
    triggerOnce: true,
  });

  const navigate = useNavigate();

  const handleClickGetQuoate = () => {
    // navigate("/contact");
    window.scroll(0, 2520);
  };

  const handleScrollClick = () => {
    window.scroll(0, 700);
  };

  const imgd = useRef(null);

  const handleViewClick = () => {
    navigate("/blogs");
    window.scrollTo(0, 0);
  };

  const handleKnowClick = () => {
    navigate("/about-us");
    window.scrollTo(0, 0);
  };

  const [popUp, setPopUp] = useState(false);

  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null); // State to store the index of the selected blog

  const handleReadMoreClick = (index) => {
    setSelectedBlogIndex(index); // Set the index of the clicked blog
    setPopUp(true); // Open the popup
  };

  // For animations
  const [ref, inView] = useInView();

  const { scrollYProgress } = useScroll();

  const [refBlogs, inViewBlogs] = useInView({
    triggerOnce: true,
  });

  const [refHighLights, inViewHighLights] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <Header />
      <div className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">
        {/* ---------------------------------------------- Our Brands ---------------------------------------------- */}
        <div className="home-banner-kaash position-relative">
          <div>
            <video
              id="background-video"
              className="w-100 inline video-wrapper video-player hero-video-design"
              controls={false}
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <h1
            id="text-overlay"
            className="col-11 col-md-10 col-lg-8 mx-auto home-heading-title fade-in"
          >
            Create anything you can imagine <br />
            <span className="orange-color">with 3D Printing</span>
          </h1>
        </div>

        <div className="single-background">
          {/* ---------------------------------------------- Story ---------------------------------------------- */}
          <section id="ourStory" className=" h-100 nav-link ">
            <div className="scroll-animation pb-xl-5 pt-3 pb-5 text-white ">
              <div
                className={`container pt-xl-5 pb-xl-5  mt-lg-1 mt-2 zoom-content `}
                // style={{ transform: `scale(${zoomScale})` }}
              >
                <div className="d-flex flex-wrap pt-xl-5 mt-xl-5 align-items-center">
                  <div
                    className={`col-lg-12 col-xl-6 col-12 mt-md-4 rounded ${
                      inView && "fade-in"
                    }`}
                  >
                    <img
                      src={require("./Images/groupimg.png")}
                      alt=""
                      className="img-fluid our-story-image-group d-none d-md-block rounded"
                    />
                  </div>
                  <div className={`col-xl-6 col-12 px-xl-5 `}>
                    <p
                      className="text-heading-orange mt-2 mb-1 mt-lg-4 mt-md-4"
                      ref={ref}
                    >
                      Kaash Studio
                    </p>
                    <h1 id="section-id">Our Story Unfolded</h1>
                    <img
                      src={require("./Images/groupimg.png")}
                      alt=""
                      className="img-fluid d-md-none rounded my-2"
                    />
                    <p className="mt-4" style={{ textAlign: "justify" }}>
                      At Kaash Studio, our journey is a testament to the power
                      of passion and innovation. Founded with a vision to
                      redefine the possibilities of 3D printing and design, our
                      story began in ’23 as a humble endeavor.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      {" "}
                      At our core, we are more than a company; we are creators,
                      innovators, and visionaries. The narrative that defines us
                      – from humble beginnings to becoming a driving force in 3D
                      printing and designing. Every project, every
                      collaboration, contributes to a legacy built on excellence
                      and creativity.
                    </p>
                    <button
                      className="text-dark p-2 px-4 rounded border-muted text-secondary knowmore-btn bg-white"
                      onClick={handleKnowClick}
                    >
                      Know more <FaArrowRightLong fill="grey" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ---------------------------------------------- Brands ---------------------------------------------- */}
          <section id="ourbrands" className="pt-3 pt-lg-0">
            <div className="unique-offerings  scroll-animation px-2">
              <OurBrands
                className={` mt-lg-1 mt-2 zoom-content ${
                  inView ? "fade-in" : ""
                } `}
              />
            </div>
          </section>

          {/* ---------------------------------------------- Blogs ---------------------------------------------- */}
          <section id="blogs" className="pt-3 pt-lg-0">
            <div className="container pt-lg-5">
              <div className="d-flex flex-wrap  pt-xl-0 justify-content-between mb-lg-0 mb-2 mb-xl-1">
                <h1 className="px-lg-3 px-md-3 text-white">Blogs & News</h1>
                <div className="col-xl-3 col-lg-4 pe-xl-4 pe-md-3">
                  <button
                    className="text-dark w-100 p-2 bg-white shadow border-muted border rounded fw-semibold  "
                    onClick={handleViewClick}
                  >
                    {" "}
                    View All <FaArrowRightLong />
                  </button>
                </div>
              </div>

              <div className="d-flex flex-wrap pt-5 pt-lg-4  justify-content-between">
                {BlogsData.slice(0, 3).map((data, index) => (
                   <div
                   className={`col-12  pe-md-3 px-md-3 mb-4 col-xl-4 mt-xl-0 col-lg-6 px-xl-3  col-md-6 pe-lg-2 px-lg-2  `}
                 >
                   <div
                     onClick={() => handleReadMoreClick(index)}
                     className="text-dark cursor-pointer card card-view-height "
                   >
                     <div className=" ">
                       <img
                         src={data.img}
                         alt=""
                         height={200}
                         width="100%"
                         className="rounded-top blog-img-card"
                       />
                     </div>
                     <div
                       style={{
                         backgroundColor: "#f5f7fa",
                         height: "100%",
                       }}
                       className="card-body rounded-bottom  "
                     >
                       <h4 className="h-50 blog-card-heading">{data.title}</h4>
                     </div>
                     <span
                       style={{
                         backgroundColor: "#f5f7fa",
                       }}
                       className="text-secondary px-3 blog-text-span"
                     >
                       {data.date}
                     </span>
                     <div
                       style={{ backgroundColor: "#f5f7fa" }}
                       className="card-footer border-0 mt-0 pt-2 "
                     >
                       <button
                         onClick={() => handleReadMoreClick(index)}
                         className={` w-100 p-2 rounded blog-viewall-btn border-1 border-secondary shadow-on-hover`}
                       >
                         Read More{" "}
                         <FaArrowRightLong
                           className="arrow-icon-blog"
                           size={15}
                         />
                       </button>
                     </div>
                   </div>
                 </div>
                ))}
              </div>
            </div>
          </section>

          {/* ---------------------------------------------- Highlights ---------------------------------------------- */}
          <section>
            <div className="container mt-lg-5 mt-5 mb-5 pb-lg-1 pt-lg-3 pt-xl-0 mt-xl-0">
              <h6
                className={`text-center client-higlights-heading ${
                  inViewHighLights && "fade-in"
                }`}
                ref={refHighLights}
              >
                CLIENT HIGHLIGHTS
              </h6>
              <hr />
              <Swiper
                modules={[
                  Navigation,
                  Autoplay,
                  Pagination,
                  Scrollbar,
                  A11y,
                  FreeMode,
                ]}
                autoplay={{ delay: 0.00001 }}
                // spaceBetween={50}
                // slidesPerView={3}
                // pagination={{ clickable: true }}
                // onActiveIndexChange={handleSlideChange}
                // centeredSlides
                className="my-md-4"
                // navigation
                breakpoints={{
                  0: { slidesPerView: 2 },
                  480: { slidesPerView: 2 },
                  576: { slidesPerView: 4 },
                  // 1200: { slidesPerView: 3 },
                }}
                loop
                speed={2500}
                style={{ backgroundColor: "#E5E5E6" }}
              >
                {HighlightsItems.map((data, i) => (
                  <SwiperSlide className="text-center p-3" key={i}>
                    <div className="mx-md-4 mx-2">
                      <img
                        src={data}
                        alt="Company Logo"
                        className="img-fluid w-100 px-2"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <hr />
            </div>
          </section>

          {/* ---------------------------------------------- Contact ---------------------------------------------- */}
          <section id="contact">
            <div className="p-xl-4 px-0 pt-0 pt-lg-5  contact-form ">
              <ContactForm />
            </div>
          </section>
        </div>
      </div>

      {/* Blogs Popup  */}
      {BlogsData.map((data, index) => (
        <Modal
          show={popUp && selectedBlogIndex === index}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          key={index}
          className=""
        >
          <Modal.Header className=" border-bottom-0">
            <Modal.Title className="" id="contained-modal-title-vcenter">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="orange-color text-center fw-normal px-5 p-1 fs-6 rounded-5 orange-bg ">
                  BLOGS
                </div>
              </div>
            </Modal.Title>
            <IoClose
              size={25}
              className="cursor-pointer"
              onClick={() => setPopUp(false)}
            />
          </Modal.Header>
          <Modal.Body className=" p-3">
            <img src={data.img} className="img-fluid rounded" />
            <div className="pt-3">
              <h4>{data.title}</h4>
              <div>
                <p style={{ textAlign: "justify" }} className="">
                  {data.paragraphA}
                </p>
                <h6>
                  {data.linkHeading}{" "}
                  <a className="" target="_blank" href={data.link}>
                    {data.linkName}
                  </a>
                </h6>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ))}

      <Footer />
    </>
  );
};

const HighlightsItems = [
  require("./Images/logojohndeere.png"),
  require("./Images/datart2.png"),
  require("./Images/Logos Grayscale.png"),
  require("./Images/logossr.png"),
  require("./Images/mizani.png"),
  require("./Images/prayosha.png"),
  require("./Images/prayosha2.png"),
];

export default NewHomePage;
