import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "./CustomParallaxHome.css";
import { useLocation } from "react-router-dom";

// Images
import BannerVideo from "./Images/video-new.mp4";

import SeperateBg from "./Images/kaash-seperate-bg.png";

// Story
import { FaArrowRightLong } from "react-icons/fa6";

// Brands
import OurBrands from "./Components/OurBrands";

// Blogs
import { BlogsData } from "../Blogs/Components/BlogData";
import { IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";

// Highlights and Contact
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Mousewheel,
  Keyboard,
} from "swiper/modules";
import ContactForm from "../../components/ContactForm";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function ParallaxSlider() {
  // Check component
  const [currentBack, setCurrentBack] = useState(0);
  const location = useLocation();
  const path = location.pathname;

  // Check scrolling
  const [scrollUp, setScrollUp] = useState(null);

  // Navigate
  const navigate = useNavigate();

  // Views
  const [refBanner, inViewBanner] = useInView();
  const [refStory, inViewStory] = useInView();
  const [refBrands, inViewBrands] = useInView();
  const [refBlogs, inViewBlogs] = useInView();
  const [refHighLights, inViewHighLights] = useInView();
  const [refContacts, inViewContacts] = useInView();

  useEffect(() => {
    const data = [
      inViewBanner,
      inViewStory,
      inViewBrands,
      inViewBlogs,
      inViewContacts,
      inViewHighLights,
    ];
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        setCurrentBack(i);
      }
    }
  }, [
    inViewBanner,
    inViewStory,
    inViewBrands,
    inViewBlogs,
    inViewHighLights,
    inViewContacts,
  ]);

  // Handle scroll
  const data = [
    inViewBanner,
    inViewStory,
    inViewBrands,
    inViewBlogs,
    inViewContacts,
    inViewHighLights,
  ];
  const handleWheelChange = (e) => {
    // console.log('wheel event', e.deltaY)
    if (e.deltaY > 0) {
      setScrollUp(false);
    } else {
      setScrollUp(true);
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        setCurrentBack(i);
      }
    }
  };

  // Blogs
  const [popUp, setPopUp] = useState(false);
  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const handleViewClick = () => {
    navigate("/blogs");
    window.scrollTo(0, 0);
  };
  const handleReadMoreClick = (blogIndex) => {
    setSelectedBlogIndex(blogIndex); // Set the index of the clicked blog
    setPopUp(true); // Open the popup
  };

  // Redirection
  const [swiper, setSwiper] = useState(null);
  const swiperRef = useRef(null);

  const handleLinkClick = (slideIndex) => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(slideIndex);
    }
  };

  useEffect(() => {
    if (swiperRef.current !== null && swiper === null) {
      setSwiper(swiperRef.current.swiper);
    }
  }, [swiper, setSwiper]);

  // Story
  const handleKnowClick = () => {
    navigate("/about-us", { state: { data: "from_aboutUs" } });
    window.scrollTo(0, 0);
  };

  const dataHome = location?.state?.data;

  useEffect(() => {
    const dataHome = location?.state?.data;
    if (dataHome === "home" && swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(0);
    }
    if (dataHome === "story" && swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(1);
      console.log("STORY---->>", dataHome);
    }
    if (dataHome === "brand" && swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(2);
    }
    if (dataHome === "blog" && swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(3);
    }
    if (dataHome === "contact" && swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(4);
    }
  }, [location.state]);

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <div className="custom-parallax parallax-slider position-relative overflow-hidden">
      {/* Backgrounds  */}
      <div className="backgrounds">
        {currentBackgrounds.map((bg, i) => (
          <div
            key={i}
            className={`position-relative h-100 ${
              currentBack === i ? "visible" : "visually-hidden"
            }`}
          >
            <div
              className={`h-100 w-100 position-absolute top-0 start-0 ${
                currentBack === i && currentBack === 0 && "fading-out"
              }`}
            >
              {scrollUp !== null &&
                (scrollUp
                  ? currentBackgrounds[i + 1]
                  : currentBackgrounds[i - 1])}
            </div>
            <div
              className={`h-100 w-100 start-fading position-absolute top-0 start-0 ${
                currentBack === i &&
                (currentBack === 0 || currentBack === 1) &&
                "fading-in"
              }`}
            >
              {bg}
            </div>
          </div>
        ))}
      </div>

      {/* Content */}
      <div className="start-with" onWheel={handleWheelChange}>
        <Swiper
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            // Autoplay,
            FreeMode,
            Mousewheel,
            Keyboard,
          ]}
          spaceBetween={0}
          slidesPerView={1}
          style={{ height: "100vh" }}
          className="text-white"
          direction="vertical"
          // delay={0.001}
          autoplay={false}
          speed={1000}
          ref={swiperRef}
          pagination={{ clickable: true }}
          onSlideChange={handleWheelChange}
          mousewheel={{
            enable: true,
          }}
          keyboard={{
            enabled: true,
            pageUpDown: true,
          }}
        >
          {/* Banner  */}
          <SwiperSlide id="home" className="h-100 d-flex container banner">
            {path === "/about-us" || path === "/blogs" ? (
              ""
            ) : (
              <Header linkClick={handleLinkClick} />
            )}
            <div className="col-12 m-auto">
              <div className="">
                <h1
                  className={` text-center banner-title start-fading ${
                    inViewBanner && "content-fade-in"
                  }`}
                  ref={refBanner}
                >
                  Create anything you can imagine <br />
                  <span className="orange-color">with 3D Printing</span>
                </h1>
              </div>
            </div>
          </SwiperSlide>

          {/* Story  */}
          <SwiperSlide id="story" className="h-100 d-flex container">
            <div className="col-12 m-auto">
              <div
                className={`  zoom-content`}
                // style={{ transform: `scale(${zoomScale})` }}
                id="ourstory"
              >
                <div className="d-flex flex-wrap align-items-center">
                  <div
                    className={`col-lg-12 col-xl-6 col-12 mt-md-4 rounded start-fading ${
                      inViewStory && "content-fade-in"
                    }`}
                    ref={refStory}
                  >
                    <img
                      src={require("./Images/groupimg.png")}
                      alt=""
                      className="img-fluid our-story-image-group d-none d-md-block rounded rounded-3"
                    />
                  </div>
                  <div
                    className={`col-xl-6 col-12 px-xl-5 start-fading ${
                      inViewStory && "content-fade-in"
                    }`}
                  >
                    <p className="text-heading-orange mt-2 mb-1 mt-lg-4 mt-md-4">
                      Kaash Studio
                    </p>
                    <h1 id="section-id">Our Story Unfolded</h1>
                    <img
                      src={require("./Images/groupimg.png")}
                      alt=""
                      className="img-fluid d-md-none rounded my-2"
                    />
                    <p
                      className="para-ourstory"
                      style={{ textAlign: "justify" }}
                    >
                      At Kaash Studio, our journey is a testament to the power
                      of passion and innovation. Founded with a vision to
                      redefine the possibilities of 3D printing and design, our
                      story began in ’23 as a humble endeavour.
                    </p>
                    <p
                      className="para-ourstory"
                      style={{ textAlign: "justify" }}
                    >
                      {" "}
                      At our core, we are more than a company; we are creators,
                      innovators, and visionaries. The narrative that defines us
                      – from humble beginnings to becoming a driving force in 3D
                      printing and designing. Every project, every
                      collaboration, contributes to a legacy built on excellence
                      and creativity.
                    </p>
                    <button
                      className="text-dark p-2 px-4  border-muted text-secondary knowmore-btn "
                      onClick={handleKnowClick}
                    >
                      Know more <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Brands  */}
          <SwiperSlide id="brands" className="h-100 d-flex container">
            <div ref={refBrands} className="col-12 m-auto">
              <div
                id="ourbrands"
                className={` mt-lg-1 mt-2  start-fading ${
                  inViewBrands && "content-fade-in"
                }`}
              >
                <OurBrands linkClick={handleLinkClick} />
              </div>
            </div>
          </SwiperSlide>

          {/* Blogs  */}
          <SwiperSlide id="blogs" className="h-100 d-flex container">
            <div className="col-12 m-auto">
              <div ref={refBlogs}>
                <div
                  className={`d-flex flex-wrap pt-5 pt-xl-0 justify-content-between mb-lg-0 mb-2 mb-xl-0 `}
                >
                  <h1
                    className={`px-lg-3 blogs-title px-md-3 start-fading ${
                      inViewBlogs && "content-fade-in"
                    }`}
                  >
                    Blogs & News
                  </h1>
                  <div
                    className={`col-xl-3 col-lg-4 pe-xl-4 pe-md-3 start-fading ${
                      inViewBlogs && "content-fade-in"
                    }`}
                  >
                    <button
                      className="text-dark w-100 p-2  blog-view-btn shadow border-muted border  fw-semibold  "
                      onClick={handleViewClick}
                    >
                      {" "}
                      View All <FaArrowRightLong />
                    </button>
                  </div>
                </div>

                <div
                  ref={refBlogs}
                  className={`d-flex flex-wrap  justify-content-between mt-3 pt-5`}
                >
                  {BlogsData.slice(0, 3).map((data, index) => (
                    <div
                      className={`col-12  pe-md-3 px-md-3 mb-4 col-xl-4 mt-xl-0 col-lg-6 px-xl-3  col-md-6 pe-lg-2 px-lg-2 start-fading ${
                        inViewBlogs && "content-fade-in"
                      } `}
                    >
                      <div
                        onClick={() => handleReadMoreClick(index)}
                        className="text-dark cursor-pointer card card-view-height "
                      >
                        <div className=" ">
                          <img
                            src={data.img}
                            alt=""
                            height={200}
                            width="100%"
                            className="rounded-top blog-img-card"
                          />
                        </div>
                        <div
                          style={{
                            backgroundColor: "#f5f7fa",
                            height: "100%",
                          }}
                          className="card-body rounded-bottom  "
                        >
                          <h4 className="h-50 blog-card-heading">
                            {data.title}
                          </h4>
                        </div>
                        <span
                          style={{
                            backgroundColor: "#f5f7fa",
                          }}
                          className="text-secondary px-3 blog-text-span"
                        >
                          {data.date}
                        </span>
                        <div
                          style={{ backgroundColor: "#f5f7fa" }}
                          className="card-footer border-0 mt-0 pt-2 "
                        >
                          <button
                            onClick={() => handleReadMoreClick(index)}
                            className={` w-100 p-2 rounded blog-viewall-btn border-1 border-secondary shadow-on-hover`}
                          >
                            Read More{" "}
                            <FaArrowRightLong
                              className="arrow-icon-blog"
                              size={15}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Contact  */}
          <SwiperSlide id="contacts" className="h-100 d-flex container">
            <div ref={refContacts} className="col-12 m-auto">
              <div
                className={`pt-md-5 pt-4 start-fading ${
                  inViewContacts && "content-fade-in"
                }`}
              >
                <ContactForm handleLinkClick={handleLinkClick} />
              </div>
            </div>
          </SwiperSlide>

          {/* Highlights */}
          <SwiperSlide
            id="highLights"
            className="h-100 d-flex flex-wrap flex-column justify-content-between"
          >
            <div className="col-12 m-auto">
              <>
                <h6
                  className={`text-center client-higlights-heading 
                 
                  `}
                  ref={refHighLights}
                >
                  CLIENT HIGHLIGHTS
                </h6>
                <hr
                  className={`mb-0 start-fading ${
                    inViewHighLights && "content-fade-in"
                  }`}
                />
                <Swiper
                  modules={[Navigation, Autoplay, Pagination, Scrollbar]}
                  autoplay={{ delay: 0.00001 }}
                  className={`py-md-4 `}
                  style={{ backgroundColor: "white" }}
                  breakpoints={{
                    0: { slidesPerView: 2 },
                    480: { slidesPerView: 2 },
                    576: { slidesPerView: 4 },
                    // 1200: { slidesPerView: 3 },
                  }}
                  loop
                  speed={2500}
                >
                  {HighlightsItems.map((data, i) => (
                    <SwiperSlide className="text-center" key={i}>
                      <div className="mx-md-4 mx-2">
                        <img
                          src={data}
                          alt="Company Logo"
                          className="img-fluid w-100 px-3"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <hr
                  className={`mt-0 start-fading ${
                    inViewHighLights && "content-fade-in"
                  }`}
                />
              </>
            </div>
            <div className="mb-0">
              <Footer linkClick={handleLinkClick} />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Blogs Popup  */}
      {BlogsData.map((data, index) => (
        <Modal
          onHide={handleClose}
          show={popUp && selectedBlogIndex === index}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          key={index}
          className=""
        >
          <Modal.Header className=" border-bottom-0">
            <Modal.Title className="" id="contained-modal-title-vcenter">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="orange-color-label-blog text-center fw-normal px-5 p-1  rounded-5 orange-bg ">
                  BLOGS
                </div>
              </div>
            </Modal.Title>
            <IoClose
              size={25}
              className="cursor-pointer close-btn-blog-popup"
              onClick={() => setPopUp(false)}
            />
          </Modal.Header>
          <Modal.Body className=" p-3">
            <img
              src={data.img}
              alt={`Blogs-${index + 1}`}
              className="img-fluid w-100 rounded"
            />
            <div className="pt-3">
              <h4>{data.title}</h4>
              <div>
                <p style={{ textAlign: "justify" }} className="">
                  {data.paragraphA}
                </p>
                <h6>
                  {data.linkHeading}{" "}
                  <a rel="noopener noreferrer" target="_blank" href={data.link}>
                    {data.linkName}
                  </a>
                </h6>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ))}
    </div>
  );
}

const HighlightsItems = [
  require("./Images/logojohndeere.png"),
  require("./Images/datart2.png"),
  require("./Images/Logos Grayscale.png"),
  require("./Images/logossr.png"),
  require("./Images/mizani.png"),
  require("./Images/prayosha.png"),
  require("./Images/prayosha2.png"),
  require("./Images/prospen.jpeg"),
  // require("./Images/utanz.jpeg"),
];

const currentBackgrounds = [
  <video
    // className="w-100 h-100"
    id="background-video"
    className="w-100 h-100 inline video-wrapper video-player hero-video-design"
    playsInline
    controls={false}
    muted
    autoPlay
    loop
  >
    <source src={BannerVideo} type="video/mp4" />
  </video>,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
  <img src={SeperateBg} alt="Story" className="w-100 h-100" />,
];
