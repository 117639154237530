import React from "react";
import "./Footer.css";

import { FaInstagram } from "react-icons/fa6";
import { AiOutlineLinkedin } from "react-icons/ai";
import { IoIosArrowDropup } from "react-icons/io";

import { FaRegCopyright } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = ({ linkClick }) => {
  const navigate = useNavigate();

  const handleHomeScroll = () => {
    navigate("/", { state: { data: "home" } });
    window.scrollTo(0, 0);
  };

  const handleStoryClick = () => {
    navigate("/");
    window.scrollTo(0, 480);
  };

  const handleBlogsClick = () => {
    navigate("/", { state: { data: "blog" } });
    window.scrollTo(0, 2700);
  };

  const handleContactClick = () => {
    navigate("/");
    window.scrollTo(0, 4800);
  };

  const location = useLocation();

  const handleTopScroll = () => {
    window.scrollTo(0, 0);
  };

  const handleStoryHideClick = () => {
    navigate("/", { state: { data: "story" } });
    window.scrollTo(0, 480);
  };

  const handlesBlogHideClick = () => {
    navigate("/", { state: { data: "blog" } });
    window.scrollTo(0, 2000);
  };

  const handleContactHideClick = () => {
    navigate("/", { state: { data: "contact" } });
    window.scrollTo(0, 4300);
  };

  return (
    <>
      <div className="footer-bg d-none d-lg-block pt-xxl-4 pt-xl-0 pb-0  d-md-block d-xl-block shadow">
        <div className="container text-black">
          <div className="pt-4 pt-xl-0">
            <div className="d-lg-flex pt-xl-4  flex-lg-wrap d-md-flex d-md-wrap justify-content-between pb-lg-0 ">
              <div className="d-lg-flex flex-lg-wrap  d-md-flex d-md-wrap justify-content-between col-lg-5 col-xl-4 col-md-5">
                <div className="col-lg-3  text-center mt-xl-0  mt-lg-0 pe-xl-0 pe-md-2 ">
                  <img
                    src={require("../images/kaashlogo.png")}
                    alt=""
                    className="img-fluid  col-lg-12 col-xl-10    col-sm-2 col-md-9 col-3 footerlogo "
                  />
                </div>
                <div className="border-0  border-height  border-warning border-end"></div>
                <p
                  style={{ textAlign: "justify" }}
                  className="text-secondary text-center text-lg-start col-lg-6  mt-3 mt-md-0 col-md-9   "
                >
                  Create anything you imagine and start your 3D printing
                  adventure today!
                </p>
              </div>
              <div className="d-flex flex-wrap fw-semibold ms-4  ms-lg-none mt-md-0   mt-lg-0">
                <h6
                  onClick={
                    location.pathname === "/blogs" ||
                    location.pathname === "/about-us"
                      ? handleHomeScroll
                      : () => {
                          handleHomeScroll();
                          linkClick(0);
                        }
                  }
                  className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Home
                </h6>
                {location.pathname === "/blogs" ||
                location.pathname === "/about-us" ? (
                  <>
                    <h6
                      onClick={handleStoryHideClick}
                      className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer nav-link-style me-3 me-lg-0 px-lg-4`}
                    >
                      Our Story
                    </h6>

                    <h6
                      onClick={handlesBlogHideClick}
                      className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer nav-link-style me-3 me-lg-0 px-lg-4`}
                    >
                      Blogs
                    </h6>
                    <h6
                      onClick={handleContactHideClick}
                      className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer  nav-link-style me-3 me-lg-0 px-lg-4`}
                    >
                      Contact Us
                    </h6>
                  </>
                ) : (
                  <>
                    <h6
                      onClick={() => {
                        handleStoryClick();
                        linkClick(1);
                      }}
                      className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer nav-link-style me-3 me-lg-0 px-lg-4`}
                    >
                      &nbsp; &nbsp; Our Story
                    </h6>
                    <h6
                      onClick={() => {
                        handleBlogsClick();
                        linkClick(3);
                      }}
                      className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer  nav-link-style me-3 me-lg-0 px-lg-4`}
                    >
                      &nbsp; &nbsp; Blogs
                    </h6>
                    <h6
                      onClick={() => {
                        handleContactClick();
                        linkClick(4);
                      }}
                      className={`text-decoration-none fw-semibold cursor-pointer nav-links-text-footer  nav-link-style me-3 me-lg-0 px-lg-4`}
                    >
                      &nbsp; &nbsp; Contact Us &nbsp; &nbsp;
                    </h6>
                  </>
                )}
              </div>
            </div>

            <div className="d-flex flex-wrap justify-content-center mb-md-3  mt-lg-1 mb-2">
              <div className="mx-2 ">
                <a
                  href="https://www.instagram.com/kaashstudio/?igsh=MXl3bDNjaHNuYmxw"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black"
                >
                  <div className="bg-white icon-div rounded-circle ">
                    <FaInstagram
                      size={45}
                      className="shadow p-2 rounded-circle icon-style"
                    />
                  </div>
                </a>
              </div>

              <div className="mx-2 ">
                <a
                  href="https://www.linkedin.com/company/kaash-studio/?originalSubdomain=in"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black "
                >
                  <div className="bg-white icon-div rounded-circle ">
                    <AiOutlineLinkedin
                      size={45}
                      className="icon-style shadow p-2 rounded-circle"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-between ">
            <small className="px-lg-3 mt-md-2  ">
              Kaash Studio LLP &nbsp;
              <FaRegCopyright size={16} /> &nbsp; 2024 All Rights Reserved
            </small>
            <div className="pe-lg-3 mb-2 ">
              {" "}
              <IoIosArrowDropup
                className=" cursor-pointer scroll-circle-footer mb-md-3"
                size={33}
                onClick={
                  location.pathname === "/blogs" ||
                  location.pathname === "/about-us"
                    ? handleTopScroll
                    : () => {
                        handleHomeScroll();
                        linkClick(0);
                      }
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view */}

      <div className="footer-mobile mt-xl-5 d-lg-none  d-md-none d-block d-xl-none">
        <img
          src={require("../images/footermobile.png")}
          className="shadow footer-banner-mobile "
          height={380}
          width="100%"
          alt=""
        />
        <div className="footer-mobile-section">
          <div className="text-center">
            <img src={require("../images/kaashlogo.png")} className="col-3" />
          </div>
          <p className="mt-4 text-center">
            Create anything you imagine and start your 3D printing adventure
            today!
          </p>
          <div className="d-flex fw-semibold flex-wrap  justify-content-center pb-1">
            <a
              onClick={handleHomeScroll}
              className={`text-decoration-none  cursor-pointer nav-links-text-footer on-hover fw-semibold  nav-link-style me-3 me-lg-0 px-lg-4`}
            >
              Home
            </a>
            {location.pathname === "/about-us" ||
            location.pathname === "/blogs" ? (
              <>
                <a
                  href="/#ourStory"
                  onClick={handleStoryClick}
                  className={`text-decoration-none cursor-pointer nav-links-text-footer fw-semibold  on-hover nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Our Story
                </a>
                <a
                  onClick={handleBlogsClick}
                  href="#blogs"
                  className={`text-decoration-none cursor-pointer nav-links-text-footer on-hover fw-semibold  nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Blogs
                </a>
                <a
                  onClick={handleContactClick}
                  href="/#contact"
                  className={`text-decoration-none cursor-pointer nav-links-text-footer on-hover fw-semibold  nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Contact Us
                </a>
              </>
            ) : (
              <>
                <a
                  // href="/#ourstory"
                  onClick={handleStoryHideClick}
                  className={`text-decoration-none cursor-pointer nav-links-text-footer fw-semibold on-hover nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Our Story
                </a>
                <a
                  href="/#blogs"
                  onClick={handlesBlogHideClick}
                  className={`text-decoration-none cursor-pointer nav-links-text-footer on-hover fw-semibold nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Blogs
                </a>
                <a
                  href="/#contact"
                  onClick={handleContactHideClick}
                  className={`text-decoration-none cursor-pointer nav-links-text-footer on-hover fw-semibold  nav-link-style me-3 me-lg-0 px-lg-4`}
                >
                  Contact Us
                </a>
              </>
            )}
          </div>
          <div className="d-flex flex-wrap justify-content-center mt-4">
            <div className="mx-2">
              <a
                href="https://www.instagram.com/kaashstudio/?igsh=MXl3bDNjaHNuYmxw"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
              >
                <div className="">
                  <FaInstagram
                    size={45}
                    className="shadow p-2 rounded-circle icon-style"
                  />
                </div>
              </a>
            </div>

            <div className="mx-2 ">
              <a
                href="https://www.linkedin.com/company/kaash-studio/?originalSubdomain=in"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black "
              >
                <div className=" ">
                  <AiOutlineLinkedin
                    size={45}
                    className="icon-style shadow p-2 rounded-circle"
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="d-flex px-2  copyright-footer-mobile flex-wrap justify-content-between mt-4  ">
            <small className="px-lg-3 fw-semibold pt-3">
              Kaash Studio LLP &nbsp;
              <FaRegCopyright size={16} /> &nbsp; 2024 All Rights Reserved
            </small>
            <div className="pe-lg-3 arrow-icon-footer">
              <IoIosArrowDropup
                className="d-block  cursor-pointer d-lg-none mt-2 "
                size={25}
                onClick={handleTopScroll}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
