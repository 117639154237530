import React, { useState } from "react";

import "./Architects2.css";
import { useInView } from "react-intersection-observer";

const Architects = () => {
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);

  const Images = {
    image1: new URL("../images/shrinivas.jpeg", import.meta.url).href,
    image1Hover: new URL("../images/shrinivas.png", import.meta.url).href,
    image2: new URL("../images/kanishk.jpeg", import.meta.url).href,
    image2Hover: new URL("../images/kanishk.png", import.meta.url).href,
    image3: new URL("../images/aashi.JPG", import.meta.url).href,
    image3Hover: new URL("../images/aashi.png", import.meta.url).href,
  };

  const handleImageHover1 = () => {
    setIsFlipped1(!isFlipped1);
  };

  const handleImageHover2 = () => {
    setIsFlipped2(!isFlipped2);
  };

  const handleImageHover3 = () => {
    setIsFlipped3(!isFlipped3);
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <div className="container architects-kaash">
        <h2 className="px-lg-5 architects-heading">
          Introducing the Architects of Imagination
        </h2>
        <div
          className="d-flex flex-wrap justify-content-between pt-5"
          ref={ref}
        >
          <div
            className={`col-lg-4 col-md-4 col-sm-12 col-12 pe-lg-5 px-lg-5 px-md-2 flip-container-archi mb-3 mb-lg-0 ${
              inView && "fade-in"
            }`}
            onMouseEnter={handleImageHover1}
            onMouseLeave={handleImageHover1}
          >
            <div className={`flipper-archi ${isFlipped1 ? "hover" : ""}`}>
              <div className="front-archi">
                <img src={Images.image1} alt="" className="img-fluid rounded" />
              </div>
              <div className="back-archi">
                <img
                  src={Images.image1Hover}
                  alt=""
                  className="shadow img-fluid rounded"
                />
              </div>
            </div>
            <h4 className="text-center architects-client-title">
              Shrinivas Balwadkar
            </h4>
            <p className="orange-architech-text text-center">
              Chief Technology Officer (CTO)
            </p>
          </div>

          <div
            className={`col-lg-4 col-md-4 col-12 col-sm-12 pe-lg-5 px-lg-5 px-md-2 flip-container-archi mb-3 mb-lg-0 ${
              inView && "fade-in"
            }`}
            onMouseEnter={handleImageHover2}
            onMouseLeave={handleImageHover2}
          >
            <div className={`flipper-archi ${isFlipped2 ? "hover" : ""}`}>
              <div className="front-archi">
                <img src={Images.image2} alt="" className="img-fluid rounded" />
              </div>
              <div className="back-archi">
                <img
                  src={Images.image2Hover}
                  alt=""
                  className="shadow img-fluid rounded"
                />
              </div>
            </div>
            <h4 className="text-center architects-client-title">
              Kanishk Patel
            </h4>
            <p className="orange-architech-text text-center">
              Chief Operating Officer (COO)
            </p>
          </div>

          <div
            className={`col-lg-4 col-md-4 col-sm-12 col-12 pe-lg-5 px-lg-5 px-md-2 flip-container-archi mb-3 mb-lg-0 ${
              inView && "fade-in"
            }`}
            onMouseEnter={handleImageHover3}
            onMouseLeave={handleImageHover3}
          >
            <div className={`flipper-archi ${isFlipped3 ? "hover" : ""}`}>
              <div className="front-archi">
                <img
                  src={Images.image3}
                  alt=""
                  className="img-fluid rounded "
                />
              </div>
              <div className="back-archi">
                <img
                  src={Images.image3Hover}
                  alt=""
                  className="img-fluid shadow rounded"
                />
              </div>
            </div>
            <h4 className="text-center architects-client-title ">
              Aashi Jhunjunwala
            </h4>
            <p className="orange-architech-text text-center">
              Chief Marketing Officer (CMO)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Architects;
