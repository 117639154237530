import React, { useState, useEffect, useRef } from "react";
import "./Header.css";

import { IoIosArrowBack } from "react-icons/io";
import { RiHome2Line } from "react-icons/ri";
import { MdHistoryEdu } from "react-icons/md";
import { TbBrandSnowflake } from "react-icons/tb";
import { FaBloggerB } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineMenu } from "react-icons/md";

import { Nav, Navbar, Offcanvas } from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";

const HeaderAbout = ({ data }) => {
  const navigate = useNavigate();

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkIsMobileView = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    checkIsMobileView();
    window.addEventListener("resize", checkIsMobileView);

    return () => {
      window.removeEventListener("resize", checkIsMobileView);
    };
  }, []);

  const [showOffcanvas, setShowOffcanvas] = useState();

  const handleContactClick = () => {
    navigate("/", { state: { data: "contact" } });
    setShowOffcanvas(false);
  };

  const handleHomeClick = () => {
    navigate("/", { state: { data: "home" } });
    window.scrollTo(0, 0);
    setShowOffcanvas(false);
  };

  const handleStoryClick = () => {
    navigate("/", { state: { data: "story" } });
    setShowOffcanvas(false);
  };

  const handleBrandClick = () => {
    navigate("/", { state: { data: "brand" } });
    setShowOffcanvas(false);
  };

  const handleBlogClick = () => {
    setShowOffcanvas(false);
    navigate("/", { state: { data: "blog" } });
  };

  const location = useLocation();

  const handlePageStoryClick = () => {
    setShowOffcanvas(false);
    navigate("/");
    window.scrollTo(0, 470);
  };

  const handlePageBrandsClick = () => {
    setShowOffcanvas(false);
    navigate("/");
    window.scrollTo(0, 1250);
  };

  const handlePageBlogsClick = () => {
    setShowOffcanvas(false);
    navigate("/");
    window.scrollTo(0, 2700);
  };

  const handlePageContactClick = () => {
    navigate("/");
    window.scrollTo(0, 4500);
  };

  const path = location.pathname;

  return (
    <>
      <div className=" w-100 ">
        <div className="container">
          <Navbar expand="xl ">
            <Link to="/" className="header-logo py-2 navbar-brand me-4">
              <div className="col-5">
                <img
                  src={require("../images/kaashlogo.png")}
                  alt="Logo"
                  className="img-fluid  kaash-logo-img"
                />
              </div>
            </Link>
            {path === "/blogs" || path === "/about-us" ? (
              <MdOutlineMenu
                fill="black"
                size={30}
                className="d-xl-none  d-xxl-none"
                onClick={() => setShowOffcanvas(true)}
              />
            ) : (
              <MdOutlineMenu
                fill="white"
                size={30}
                className="d-xl-none d-xxl-none"
                onClick={() => setShowOffcanvas(true)}
              />
            )}

            <Navbar.Offcanvas
              show={!!showOffcanvas}
              onHide={() => setShowOffcanvas(false)}
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
              className={!isMobileView ? "" : "w-75 shadow rounded"}
              style={isMobileView ? undefined : { height: "950px" }}
            >
              <Offcanvas.Header className="justify-content-start align-items-center  pt-4 ">
                <h6
                  onClick={() => setShowOffcanvas(false)}
                  className={` border-0  fw-semibold  cursor-pointer px-lg-4 text-muted  `}
                >
                  <IoIosArrowBack size={15} />
                  &nbsp; Back
                </h6>
              </Offcanvas.Header>
              <Offcanvas.Body className="xyz p-2  rounded-5 align-self-xl-end">
                <Nav className="w-100 px-3  align-items-xl-center ">
                  <button
                    onClick={handleHomeClick}
                    className={` border-0  fw-semibold bg-transparent text-decoration-none nav-links-text-black   cursor-pointer text-start px-lg-4 me-4 me-lg-0 px-0 me-0   `}
                  >
                    {showOffcanvas ? (
                      <>
                        <RiHome2Line size={25} />
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                    &nbsp; Home
                  </button>

                  <hr className="d-block d-xl-none d-md-block d-lg-block" />

                  {isMobileView ? (
                    <a
                      href={isMobileView ? "/#ourStory" : false}
                      onClick={
                        isMobileView ? handlePageStoryClick : handleStoryClick
                      }
                      className={` border-0 bg-transparent text-decoration-none   fw-semibold  nav-links-text-black cursor-pointer   px-lg-4  me-4 me-lg-0`}
                    >
                      {showOffcanvas ? (
                        <MdHistoryEdu size={25} />
                      ) : (
                        <MdHistoryEdu size={25} />
                      )}{" "}
                      &nbsp; Our Story
                    </a>
                  ) : (
                    <a
                      href={isMobileView ? "/#ourStory" : false}
                      onClick={
                        isMobileView ? handlePageStoryClick : handleStoryClick
                      }
                      className={` border-0 bg-transparent text-decoration-none   fw-semibold  nav-links-text-black cursor-pointer   px-lg-4  me-4 me-lg-0`}
                    >
                      &nbsp; Our Story
                    </a>
                  )}
                  <hr className="d-block d-xl-none d-md-block d-lg-block" />

                  {isMobileView ? (
                    <a
                      href={isMobileView ? "/#ourbrands" : false}
                      onClick={
                        isMobileView ? handlePageBrandsClick : handleBrandClick
                      }
                      className={` border-0  fw-semibold text-decoration-none  nav-links-text-black  cursor-pointer  px-lg-4 me-4 me-lg-0 `}
                    >
                      {showOffcanvas ? (
                        <TbBrandSnowflake size={25} />
                      ) : (
                        <TbBrandSnowflake size={25} />
                      )}{" "}
                      &nbsp; Our Brands
                    </a>
                  ) : (
                    <a
                      href={isMobileView ? "/#ourbrands" : false}
                      onClick={
                        isMobileView ? handlePageBrandsClick : handleBrandClick
                      }
                      className={` border-0  fw-semibold text-decoration-none  nav-links-text-black  cursor-pointer  px-lg-4 me-4 me-lg-0 `}
                    >
                      &nbsp; Our Brands
                    </a>
                  )}
                  <hr className="d-block d-xl-none d-md-block d-lg-block" />

                  {isMobileView ? (
                    <a
                      href={isMobileView ? "/#blogs" : false}
                      onClick={
                        isMobileView ? handlePageBlogsClick : handleBlogClick
                      }
                      className={` border-0  fw-semibold text-decoration-none  nav-links-text-black  cursor-pointer  px-lg-4 me-4 me-lg-0`}
                    >
                      {showOffcanvas ? (
                        <FaBloggerB size={25} />
                      ) : (
                        <FaBloggerB size={25} />
                      )}{" "}
                      &nbsp; Blogs
                    </a>
                  ) : (
                    <a
                      href={isMobileView ? "/#blogs" : false}
                      onClick={
                        isMobileView ? handlePageBlogsClick : handleBlogClick
                      }
                      className={` border-0  fw-semibold text-decoration-none  nav-links-text-black  cursor-pointer  px-lg-4 me-4 me-lg-0`}
                    >
                      &nbsp; Blogs
                    </a>
                  )}
                  <hr className="d-block d-xl-none d-md-block d-lg-block" />
                </Nav>
                <div className="d-lg-none">
                  {location.pathname === "/blogs" ||
                  location.pathname === "/about-us" ? (
                    <>
                      <a
                        href="/#contact"
                        className={`text-decoration-none  cursor-pointer nav-links-text  fw-semibold  px-3 px-md-3  px-lg-5 border-0 bg-white`}
                        onClick={handlePageContactClick}
                      >
                        <FaPhone size={25} /> &nbsp; Contact Us
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <div className="d-none  d-lg-none d-xl-block mt-md-0 mt-4 mt-xl-0">
              {location.pathname === "/blogs" ||
              location.pathname === "/about-us" ? (
                <>
                  <button
                    onClick={handleContactClick}
                    className=" text-decoration-none nav-links-text-black fw-semibold cursor-pointer d-none d-md-none d-lg-none d-xl-block d-xxl-block orange-button-kaash2 p-2 px-5  text-white  border-0  mt-0"
                  >
                    Contact Us
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default HeaderAbout;
